@import "../css/variables";

.logout-content {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & button {
    border: 1px solid $colorWhite;
    background-color: $colorWhite !important;
    color: $colorPrimary;
  }

  & button:hover {
    border: 1px solid $colorPrimary;
  }
}