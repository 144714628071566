@import "../css/variables";

select {
    background-color: $colorWhite;
    box-shadow: 0 2px 9px $colorGray-light;
    border: none;
    border-radius: $radius;
    font-family: $fontFamilyPrimary;
    font-size: 0.9rem;
    height: 2rem;
    margin: 0 5px;
    padding: 0.2rem 0.5rem;
    // width: 100%;
}
