@mixin no-margin-padding{
    margin:0;
    padding:0;
}

@mixin marginCenter{
    margin:0 auto;
}

@mixin font{
    font: normal $fontSizeMedium $fontFamilyPrimary;
}

@mixin box-sizing{
    box-sizing:border-box;
}

@mixin absolutedFull{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin flex($wraptype:wrap, $justify:space-between, $align:null, $direction:null ){
    display:flex;
    flex-wrap: $wraptype;
    justify-content: $justify;
    @if($align != null){
        align-items: $align;
    }
    @if($direction != null){
        flex-direction: $direction;
    }
}

@mixin grid($columns:1fr, $rows:null , $gap:$remSize, $width:100%){
    display:grid;
    grid-template-columns: $columns;
    grid-gap: $gap;
    @if($width != 100%){
        width: $width;
    }
    @if($rows != null){
        grid-template-rows: $rows;
    }
}

@mixin transition{
    transition: 0.4s;
    transition-timing-function: ease-in-out;
}