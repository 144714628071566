@import "../../../css/variables";

table,
tr,
td {
    border-right-style: hidden;
}

.coe-setup {

    &__form {
        display: flex;
        padding-right: 5rem;
        margin-bottom: 1rem;

        div {
            margin: 0 2rem 0 0;

            strong {
                margin: auto;
                width: 6.5rem;
                display: inline-flex;
            }
        }
    }

    &__responsibles {
        background-color: aqua;
        width: 300px;
    }
}

.coe-setup__actions {
    display: flex;
}

.coe-setup__content {
    margin: 0 4rem 0 4rem;
}

.coe-setup__details:hover {
    cursor: pointer;
}

.coe-setup__detailItem {
    margin: 10px 0 10px;
}

.coe-setup__details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.active {
    transition: 0.5s ease-out;
}

.collapse-item {
    cursor: pointer;
    color: $colorSecondary;
    padding: 5px 10px 5px 10px;
    margin: 5px;

    & div {
        padding: 5px 10px 5px 10px;
        background-color: #dfdeee;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    & table {
        color: $colorPrimary;
        transition: 0.5s ease-out;
    }

    & .coe-points__table {
        border-collapse: 'collapse';
        width: 100%;
        max-height: 0;
        transition: 0.5s ease-out;

        & th {
            padding: 6px;
            text-align: left;
        }

        & td {
            padding: 4px;
            border-right: none;
            font-size: 14px;
        }

        & .odd-row td {
            background-color: #f2f2f2;
            border: 1px solid #f2f2f2;
            border-right: 1px solid #f2f2f2;
        }
    }
}

.content-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    margin-bottom: 15px;

    & .toggle-control {
        display: flex;
        align-items: center;
    }
}

.scroller-coe {
    width: calc(100% - 20rem);
    position: absolute !important;
    top: 200px !important;
    height: calc(100% - 5rem);
}

@media screen and (min-height: 720px) {
    .scroller-coe {
        width: calc(100% - 5rem);
        height: 420px;
    }
}