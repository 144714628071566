@import "../css/variables";

.loader {
    background: rgba(202, 167, 167, 0.5);
    backdrop-filter: blur(3px);
    place-items: center;
    display: grid;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &-spinner {
        background: radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 60%);;
        color: $colorPrimary;
        font-size: 2rem;
        padding: 5rem;
    }
}
