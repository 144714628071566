@import "../../../css/variables";

.reward-category-catalog {

    &__form {
        display: flex;
        padding-right: 5rem;
        margin-bottom: 1rem;

        div {
            margin: 0 2rem 0 0;

            strong {
                margin: auto;
                width: 6.5rem;
                display: inline-flex;
            }
        }
    }
}

#action-edit {
    margin: 0 !important;
}

.reward-new-category__action {
    margin-bottom: 15px;
}