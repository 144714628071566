@import "../../../css/variables";

.coe-setup {
    &__form {
        display: flex;
        padding-right: 5rem;
        margin-bottom: 1rem;

        div {
            margin: 0 2rem 0 0;

            strong {
                margin: auto;
                width: 6.5rem;
                display: inline-flex;
            }
        }
    }

    &__areas {
        // display: grid;
        // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        margin: 0 0 2rem;

        &-info {
            align-items: center;
            background-color: #efefef;
            display: flex;
            justify-content: space-between;
            padding: 0.5rem 1rem;

            &:nth-child(even) {
                background-color: $colorGray-light;
            }
        }

        &-edit {
            display: block;
        }
    }
}

.align__filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.margin-align__filters {
    margin: 0.5rem 0.5rem;
}

.initial-filters {
    display: flex;
    background-color: $colorPrimary;
    padding: 10px;
    color: $colorWhite;
}