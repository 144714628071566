@import "../css/variables";

input {
    background-color: $colorWhite;
    box-shadow: 0 2px 9px $colorGray-light;
    border: none;
    border-radius: $radius;
    font-family: $fontFamilyPrimary;
    font-size: 0.9rem;
    height: 2rem;
    padding: 0.5rem 1rem;
    width: 100%;
    margin: 0 5px;

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: .6;
      background-color: $colorGray-light;
    }
  }