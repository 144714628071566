@import "../css/variables";

.login {
  align-items: center;
  display: flex;
  height: 100vh;

  &-container {
    align-items: center;
    background: $colorWhite;
    border-radius: $radius;
    box-shadow: $boxShadow;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    max-width: 580px;
    margin: 1rem auto;
    padding: 2rem;
    width: 90%;
  }

  &-logo {
    /* aspect-ratio: 1/1; */
    background: #040c2c;
    border-radius: 10px;
    /* height: 120px; */
    padding: 2rem;
    /* width: 120px; */
  }
}