@import "../css/variables";

main {
    display: grid;
    grid-template-columns: 210px 1fr;
    grid-gap: 1rem;
    height: calc(100vh);
    overflow: hidden;
    padding: 1rem;
}
