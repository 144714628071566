@import "../../../css/variables";

.redeem-points {

    &__form {
        display: flex;
        padding-right: 5rem;
        margin-bottom: 1rem;

        div {
            margin: 0 2rem 0 0;

            strong {
                margin: auto;
                width: 6.5rem;
                display: inline-flex;
            }
        }
    }
}

.redeem-points-points__actions {
    display: flex;
    justify-content: space-evenly;
}

.reward-new-category__action {
    margin-bottom: 15px;
}

#delete {
    background-color: $colorWhite;
    color: $colorPrimary;
    border: 1px solid $colorPrimary;
}

.input-spin {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.v-hidden {
    visibility: hidden;
}