.input-date {
    box-shadow: 0 2px 9px #d8d6de;
    border-radius: 10px;
    border: none;
    position: relative;
    margin: 0 !important;

    .label-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 !important;

        p {
            font-family: "AvantGardeMedium", sans-serif !important;
            font-size: 0.9rem !important;
            font-weight: normal;
            margin: 0 0 0 15px;
        }

        input {
            width: 55px;
            color: #fff;
            border: none !important;
            box-shadow: none !important;
        }

        input:active {
            color: #fff;
        }
    }

}