@import "../css/variables";

.sidebar {

    &-container {
        background-color: $colorPrimary;
        border: none;
        border-radius: 1rem;
        color: $colorWhite;
        height: calc(100vh - 2rem);
    }

    &-logo {
        margin: 2.5rem 1rem 2rem;
        max-width: 100px;
    }

    &-menu {

        li {
            margin: 0;

            &.active {

                a {
                    background-color: $colorTertiary;
                    color: $colorBlack;
                }
            }
        }

        a {
            color: $colorWhite;
            text-decoration: none;
            padding: 0.8rem;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            border-bottom: 0.5px solid $colorWhite;
            transition: all 0.3s;

            &:hover {
                background-color: rgba(218, 220, 224, 0.425);
            }

            .icon {
                font-size: $fontSizeLarge;
                flex: 1;
                margin-right: 1rem;
                width: 1rem;
            }

            span {
                width: 100%;
            }
        }
    }
}