@import "../css/variables";

label {
    font-weight: bold;
    margin-bottom: 1.2rem;
    width: 100%;

    &.grid {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    &.table {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 7fr;
    }
}
    
