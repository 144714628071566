@import "../css/variables";

.card {
  background-color: $colorWhite;
  box-shadow: $boxShadow;
  border-radius: $radius;
  padding: 1rem 2rem;
  margin: 1rem;

  &--main {  
    overflow-x: hidden;
    overflow-y: auto;
    align-items: baseline;
    display: grid;
    grid-template-rows: auto 1fr;
    height: calc(100% - 2rem);
    font-size: $fontSizeSmall;

    ::-webkit-scrollbar {
      width: 5px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      background: $colorGray-light;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: $colorGray-dark;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}