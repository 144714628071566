@import "../../../css/variables";

.points-by-employee {

    &__info {
        display: flex;
        padding-right: 6rem;
        margin-bottom: 1rem;

        div {
            margin: 0 2rem 0 0;

            strong {
                margin-right: 0.5rem;
            }
        }
    }

    &__filters {
        align-items: center;
        display: flex;
        margin-bottom: 1rem;
        border-top: 1px solid $colorGray-light;
        border-bottom: 1px solid $colorGray-light;

        strong {
            margin-right: 1rem;
        }

        &-dropdown {
            align-items: center;
            display: flex;
            padding: 0 0.5rem;

            span {
                font-size: $fontSizeSmall;
                margin-right: 0.5rem;
                white-space: nowrap;
            }
        }

        &-dates {
            display: flex;
            background-color: $colorPrimary;
            padding: 0.5rem 0.5rem;
            color: $colorWhite;

            div {
                margin: 0 0.5rem;
            }

            strong {
                margin: 0 0.5rem;
            }
        }
    }
}

.align__filters {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.export-action {
    margin-left: 15px;
}

.margin-align__filters {
    margin: 0.5rem 0.5rem;
}