
//Font Sizes
$fontSizeXSmall: clamp(12px, 4vw, 12px);
$fontSizeSmall: clamp(14px, 5vw, 15px);
$fontSizeMedium: clamp(16px, 1.7vw, 18px);
$fontSizeLarge: clamp(19px, 1.7vw, 21px);
$fontSizeXLarge: clamp(21px, 1.7vw, 24px);

// Fonts
$fontFamilyPrimary: 'AvantGardeMedium', sans-serif;
$fontFamilySecondary: 'AvantGardeDemi', sans-serif;

// Colors
$colorWhite: #fff;
$colorBlack: #333;
$colorPrimary: #040c2c;
$colorSecondary: #4773be;
$colorTertiary: #88b7e7;
$colorGray-light: #d8d6de;
$colorGray-dark: #999;
$colorRed: #e74c3c;

// Various
$radius: 10px;
$boxShadow: 0 10px 20px rgba(0,0,0,0.15);