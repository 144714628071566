/* reset */
html {
    @include no-margin-padding;
    -webkit-text-size-adjust:none;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
    @include font;
}

*{
    @include box-sizing;
}

body{
    @include no-margin-padding;
    @include font;
    background: $colorGray-light;
    color: $colorBlack;
}

a {
    font: normal $fontSizeSmall $fontFamilyPrimary;
    color: $colorSecondary;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

ul,li{
    list-style: none;
    @include no-margin-padding;
}

img{
    display: block;
    @include no-margin-padding;

}

h1,h2,h3,h4,h5{
    @include no-margin-padding;
    font-weight: 900;
}
