@import "../css/variables";

.form {
    margin-top: 20px;

    &.center {
        margin: 0 auto;
    }

    &.center__content {
        margin: 25px 5em;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }
}
  