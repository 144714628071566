@font-face{
    font-family: AvantGardeDemi;
    src:url(../fonts/avant-garde-demi.eot);
    src:url(../fonts/avant-garde-demi.eot?#iefix) format("embedded-opentype"),
        url(../fonts/avant-garde-demi.woff2) format("woff2"),
        url(../fonts/avant-garde-demi.woff) format("woff"),
        url(../fonts/avant-garde-demi.ttf) format("truetype")
}
@font-face{
    font-family: AvantGardeMedium;
    src:url(../fonts/avant-garde-medium.eot);
    src:url(../fonts/avant-garde-medium.eot?#iefix) format("embedded-opentype"),
        url(../fonts/avant-garde-medium.woff2) format("woff2"),
        url(../fonts/avant-garde-medium.woff) format("woff"),
        url(../fonts/avant-garde-medium.ttf) format("truetype");
    font-weight:500;
    font-style:normal;
}