@import "../css/variables";

button {
    align-items: center;
    background-color: $colorPrimary;
    border: 0;
    border-radius: $radius;
    color: $colorWhite;
    display: flex;
    font-family: $fontFamilyPrimary;
    font-size: $fontSizeSmall;
    gap: 0.5rem;
    justify-content: space-between;
    margin: 0;
    padding: 0.5rem 1rem;
    transition: all 0.4s;
    margin: 5px;

    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }

    &.secondary {
        background-color: $colorGray-dark;
    }
}