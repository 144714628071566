@import "../css/variables";

.title {
    border-bottom: 4px solid $colorPrimary;
    font-family: $fontFamilySecondary;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    padding: 0.5rem 0;
    width: 100%;
    cursor: default;

    &--centered {
        text-align: center;
    }
}