@import "variables";
@import "mixins";
@import "fonts";
@import "base";


strong {
    cursor: default;
}

span {
    cursor: default;
}

.MuiTableCell-root {
    cursor: default !important;
}

.MuiTextField-root .MuiInputBase-root:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
}

.MuiTextField-root .MuiInputBase-root:focus-visible:not(.Mui-disabled) {
    border-bottom: none !important;
}

.MuiTextField-root .MuiInputBase-root:not(.Mui-disabled):before {
    border-bottom: none !important;
}


.MuiInputBase-root .MuiInput-root:after:not(.Mui-focused):after {
    border: none !important;
    transform: none !important;
}

.message {
    margin: -10px 0 10px !important;

    & .warning {
        color: $colorSecondary;
        font-size: 12px;
    }
}

.card--limit {
    overflow-x: none !important;
    overflow-y: none !important;
    align-items: none !important;
    display: block !important;
}

.toast {
    // color: $colorSecondary !important;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 16px;
    color: $colorSecondary !important;
}

.disable-btn {
    opacity: 0.5 !important;
}

.export-excel-btn {
    cursor: pointer;
    transition: 0.5s;
}

.export-excel-btn:hover {
    opacity: 0.6 !important;
}