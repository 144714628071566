@import "../css/variables";

.modal {
    background-color: rgba(148, 148, 148, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(2px);

    &__box {
        width: 95%;
        max-width: 800px;
        padding: 2rem;
        background-color: $colorWhite;
        box-shadow: $boxShadow;
        border-radius: 1rem;
        position: relative;
    }

    &__close {
        cursor: pointer;
        display: grid;
        width: 40px;
        height: 40px;
        background-color: $colorGray-light;
        border-radius: 5px;
        box-shadow: $boxShadow;
        place-items: center;
        position: absolute;
        right: -20px;
        top: -20px;
    }

    &__scroll {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 70vh;

        &::-webkit-scrollbar {
            width: 5px;
        }
        /* Track */
        &::-webkit-scrollbar-track {
        background: $colorGray-light;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
        background: $colorGray-dark;
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
        background: #555;
        }
    }

    .title {
        border-color: $colorGray-light;
    }
}